export const ua = (function () {
  const regs = {
    // 系统
    // 'ios': /iphone|ipad|ipod/,
    android: /android/i,

    // 机型
    iphone: /iphone/i,
    ipad: /ipad/i,
    ipod: /ipod/i,

    // 环境
    weixin: /micromessenger/i,
    mqq: /QQ\//i,
    app: /inke/i,
    alipay: /aliapp/i,
    weibo: /weibo/i,

    // 浏览器
    chrome: /chrome\//i,
  }

  const ret = {}
  Object.keys(regs).forEach((key) => {
    var reg = regs[key]
    ret[key] = reg.test(navigator.userAgent)
  })

  ret.ios = ret.iphone || ret.ipad || ret.ipod
  ret.mobile = ret.ios || ret.android
  ret.pc = !ret.mobile

  ret.chrome = !!window.chrome

  return ret
})()
