<template>
  <div class="download-app">
    <div v-if="registerSuccess" class="success-text">注册成功！</div>
    <a v-if="!isInWeixin" class="download-app-button" :href="downLoadUrl" download="">点击下载App</a>
    <div v-else class="wx-modal">
      <img src="@/assets/images/browser_open.png" alt="" />
      <div class="open-browser">点击右上角选择在浏览器中打开</div>
    </div>
  </div>
</template>

<script>
import api from '@/apis'
import { ua } from '@/utils'

export default {
  name: 'download-app',
  data() {
    return {
      downLoadUrl: '',
      isInWeixin: ua.weixin,
      registerSuccess: !!this.$route.query.registerSuccess,
    }
  },
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      api.noLoginVersion().then((res) => {
        this.downLoadUrl = res.link
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.download-app {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
}
.success-text {
  font-size: 50px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40%;
  color: #fff;
}
.download-app-button {
  font-size: 40px;
  background-color: #07c160;
  border-radius: 4px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  width: 60%;
  position: absolute;
  top: 48%;
  left: 20%;
  font-weight: bold;
  color: #fff;
}
.wx-modal {
  img {
    width: 30%;
    position: absolute;
    right: 5%;
    top: 1%;
  }
  .wx-success {
  }
  .open-browser {
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: 16%;
    text-align: center;
    width: 100%;
  }
}
</style>
